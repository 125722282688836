<script setup>
    import RoboNationCore from '~/composables/RoboNationCore';
    import { ref } from 'vue';

    const gameSpeed = ref(RoboNationCore.instance.getGameSpeed());
    const onSpeedClick = function (speed) {
        RoboNationCore.instance.setGameSpeed(speed);
        gameSpeed.value = speed;
    }
</script>
<template>
    <div class="speed-controls">
        <a 
            href="#" 
            class="fast-speed"
            :data-active="gameSpeed == RoboNationCore.GAME_SPEED.FAST"
            @click="onSpeedClick(RoboNationCore.GAME_SPEED.FAST)"
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416V96C0 83.6 7.2 72.3 18.4 67s24.5-3.6 34.1 4.4L224 214.3V256v41.7L52.5 440.6zM256 352V256 128 96c0-12.4 7.2-23.7 18.4-29s24.5-3.6 34.1 4.4l192 160c7.3 6.1 11.5 15.1 11.5 24.6s-4.2 18.5-11.5 24.6l-192 160c-9.5 7.9-22.8 9.7-34.1 4.4s-18.4-16.6-18.4-29V352z" fill="currentColor"/>
            </svg>
        </a>
        <a 
            href="#" 
            class="normal-speed"
            :data-active="gameSpeed != RoboNationCore.GAME_SPEED.FAST"
            @click="onSpeedClick(RoboNationCore.GAME_SPEED.NORMAL)"
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" fill="currentColor"/>
            </svg>
        </a>
    </div>
</template>
<style>
.speed-controls {
    display:inline-block;
    margin-bottom:-5px;
}
.normal-speed, .fast-speed {
    display:inline-block;
    color:var(--color-root-white);
    opacity:0.5;
    margin-right: 10px;;
    height:14px;
}
.normal-speed {
    width:14px;
}
.fast-speed {
    width:20px;
}

.normal-speed[data-active=true], .fast-speed[data-active=true] {
    opacity:1;
}
</style>